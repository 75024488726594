import ResClient from 'resclient';

import { userEvents } from '@/utils/eventTarget.ts';

let client: ResClient;

export async function initialized() {
  if (client) {
    return client;
  }

  client = new ResClient(import.meta.env.VITE_RESGATE_ENDPOINT);

  client.setOnConnect(async () => {
    const token = localStorage.getItem('token');

    if (!token) {
      throw new Error('No authentication token found');
    }

    const strippedToken = token.split(':')[1];
    await client.authenticate('usertoken', 'login', { token: strippedToken });
  });

  return client;
}

function disconnect() {
  if (client) {
    client.disconnect();
  }
}

// Try to disconnect resgate when the user refreshes or closes the application.
// This is to prevent any lingering websocket connections.
window.addEventListener('beforeunload', () => disconnect());

// Try to disconnect resgate when the user logs out.
// When a different user logs in the connection needs to be made with their API token.
userEvents.addEventListener('loggedOut', () => disconnect());

import { type ResCollection } from 'resclient';

import { initialized } from '@/lib/resgate.ts';
import { QueueModel } from '@/lib/types/QueueModel.ts';
import { getUserFromStorage } from '@/lib/user.mjs';

/**
 * Get the user client's queues.
 *
 * This endpoint returns Queue models that holds initial data and can be used to
 * listen for changes per queue.
 *
 * We need to do some safe type casting as the resgate lib's typing is incomplete or
 * does not contain the generic types we need.
 */
export async function getClientQueues() {
  const resgate = await initialized();
  const { clientUuid } = await getUserFromStorage();
  const response = await resgate.get(`dashboard.client.${clientUuid}`);

  const collection = response as ResCollection;

  // We need at least one callback to prevent ResClient from unsubscribing.
  // See: https://resgate.io/docs/writing-clients/resclient/ (Section Listen to events).
  collection.on(null, null);

  // Remove the callback from the collection before the page is reloaded to prevent
  // ResClient from trying to reuse the old websocket after the page has reloaded.
  window.addEventListener('beforeunload', () => {
    collection.off(null, null);
  });

  return collection.toArray() as Array<QueueModel>;
}

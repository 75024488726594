import { Logger } from '@/lib/logging.mjs';
import * as settings from '@/lib/settings/remote.mjs';

import de from '@/locales/de-DE/index.json';
import en from '@/locales/en-US/index.json';
import es from '@/locales/es-ES/index.json';
import fr from '@/locales/fr-FR/index.json';
import nl from '@/locales/nl-NL/index.json';
import pt from '@/locales/pt-PT/index.json';

const logger = new Logger('i18n');

const resources = {
  'en-US': en,
  'nl-NL': nl,
  'de-DE': de,
  'fr-FR': fr,
  'es-ES': es,
  'pt-PT': pt,
};

export const availableLanguages = Object.keys(resources);
export let [chosenLanguage] = availableLanguages; // first as default

export function setTranslatedTextContent(node, key = node.dataset.translationKey) {
  node.innerText = translate(key);
  node.dispatchEvent(new CustomEvent('translated'));
}

export function setTranslatedAriaLabel(node, key = node.dataset.translationAriaLabelKey) {
  node.ariaLabel = translate(key);
}

export function setTranslatedPlaceholder(node, key = node.dataset.placeholderTranslationKey) {
  node.setAttribute('placeholder', translate(key));
}

export function translateNodes(node) {
  // Instead of adding a default parameter to the function, we do it here
  // so if we don't have a document (e.g. in ava tests) these tests don't fail
  if (!node) {
    if (typeof document === 'undefined') {
      return;
    }
    node = document.body;
  }

  for (const n of node.querySelectorAll('[data-translation-aria-label-key]')) {
    const { translationAriaLabelKey } = n.dataset;
    if (translationAriaLabelKey) {
      setTranslatedAriaLabel(n, translationAriaLabelKey);
    }
  }

  for (const n of node.querySelectorAll('[data-translation-key]')) {
    const { translationKey } = n.dataset;
    if (translationKey) {
      setTranslatedTextContent(n, translationKey);
    }
  }

  for (const n of node.querySelectorAll('[data-placeholder-translation-key]')) {
    const { placeholderTranslationKey } = n.dataset;
    if (placeholderTranslationKey) {
      setTranslatedPlaceholder(n, placeholderTranslationKey);
    }
  }
}

export function setLanguage(lng) {
  if (chosenLanguage === lng) {
    return Promise.resolve();
  }
  if (!availableLanguages.includes(lng)) {
    return Promise.reject(new Error(`unsupported language ${lng}`));
  }

  return settings.set('language', lng).then(() => {
    chosenLanguage = lng;

    document.documentElement.setAttribute('lang', lng);

    Array.from(document.getElementsByTagName('c-translate')).forEach((t) => {
      t.update();
      t.dispatchEvent(new CustomEvent('translated'));
    });

    Array.from(document.getElementsByTagName('c-language-switcher')).forEach((t) => {
      t.update();
    });

    translateNodes();
  });
}

// Shamelessly taken from https://github.com/mikemaccana/dynamic-template
// Applies a regex to replace template variables in a string.
const variablesInTemplateRegExp = /\{(.*?)}/g;
function replaceVariables(translation, variables) {
  return translation.replace(variablesInTemplateRegExp, (_, key) => variables[key]);
}

// The translation JSON files are imported in the JS (see import statements above) so we need
// to manually replace any environment variables within the translations as these JSON files
// are not going through Vite's environment variables replacing mechanism.
const environmentVariablesInTemplateRegExp = /%(.*?)%/g;
function replaceEnvironmentVariables(translation) {
  return translation.replace(environmentVariablesInTemplateRegExp, (_, key) => import.meta.env[key]);
}

export function translate(key, variables) {
  const translations = resources[chosenLanguage];

  if (!(key in translations)) {
    console.error(new Error(`undefined_translation key: ${key}`));
    return key;
  }

  if (variables) {
    return replaceEnvironmentVariables(replaceVariables(translations[key], variables));
  } else {
    return replaceEnvironmentVariables(translations[key]);
  }
}

settings.get('language').then((language) => {
  if (!availableLanguages.includes(language)) {
    logger.warn(`unsupported language: ${language}`);
    return;
  }

  if (language) {
    setLanguage(language);
    translateNodes();
  }
});
